<template>
<div class="container-xl" v-if="!$store.getters.isForbidden">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">New Record</h3>
      </div>
      <div class="card-body">
          <div class="row row-cards">
            <div class="col-md-6">
              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">Name</label>
                <div class="col">
                  <input v-model="name" type="text" class="form-control" placeholder="Enter name">
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">Type</label>
                <div class="col">
                  <el-select @change="changeType()" v-model="type" placeholder="Select">
                    <el-option
                      v-for="item in types"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">Source</label>
                <div class="col">
                  <el-select v-model="sourceId" placeholder="Select" :disabled="disabled" v-loading="isLoading">
                    <el-option
                      v-for="(item, i) in sourceList"
                      :key="i"
                      :label="item.source_name"
                      :value="item.source_id">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">Point</label>
                <div class="col">
                  <input v-model="point" type="Number" class="form-control" placeholder="Enter point">
                </div>
              </div>

              <div class="form-footer">
                <button @click="save" type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">Start At</label>
                <div class="col">
                  <el-date-picker v-model="start" type="date" placeholder="Pick a day"
                    :picker-options="pickerOptions"
                    format="dd MMMM yyyy"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </div>
              </div>

              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">End At</label>
                <div class="col">
                  <el-date-picker v-model="end" type="date" placeholder="Pick a day"
                    :picker-options="pickerOptions"
                    format="dd MMMM yyyy"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="form-label col-3 col-form-label">Number -n</label>
                <div class="col">
                  <input v-model="numberN" type="Number" class="form-control" placeholder="Enter number -n">
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { GET_ACADEMYS } from '@/store/modules/academys';
import { GET_ACTIVITYS } from '@/store/modules/activitys';
import { POST_MISSIONS } from '@/store/modules/missions';

export default {
  name: 'Missions',
  metaInfo: {
    title: 'Missions',
  },
  data() {
    return {
      isLoading: false,
      sourceList: [],
      sourceId: '',
      academic_name: '',
      activities_name: '',
      disabled: false,
      name: '',
      types: [{
        value: 'academic',
        label: 'Academic',
      }, {
        value: 'activities',
        label: 'Activities',
        disabled: true,
      }, {
        value: 'financial',
        label: 'Financial',
      }, {
        value: 'refer',
        label: 'Reference',
      }, {
        value: 'social',
        label: 'Social',
      }, {
        value: 'wa',
        label: 'Whatsapp',
      }, {
        value: 'other',
        label: 'Other',
      }],
      type: '',
      point: '',
      numberN: '',
      source: '',
      pickerOptions: {

      },
      start: '',
      end: '',
    };
  },
  computed: {},
  async mounted() {
    //
  },
  methods: {
    changeType() {
      switch (this.type) {
        case 'academic':
          console.log(this.type);
          this.sourceId = '';
          this.disabled = false;
          this.isLoading = true;
          this.getAcademyList();
          this.academic_name = this.type;
          this.activities_name = null;
          break;
        case 'activities':
          console.log(this.type);
          this.sourceId = '';
          this.disabled = false;
          this.isLoading = true;
          this.getActivitysList();
          this.academic_name = null;
          this.activities_name = this.type;
          break;
        default:
          console.log('def');
          this.sourceList = [];
          this.sourceId = '';
          this.disabled = true;
          this.sourceName = this.type;
      }
    },
    save() {
      console.log('name', this.name);
      console.log('type', this.type);
      console.log('poin', this.point);
      console.log('start', this.start);
      console.log('end', this.end);
      console.log('sort', this.numberN);
      console.log('source id', this.sourceId);
      this.postData();
    },
    async postData() {
      this.isLoading = true;
      await this.$store.dispatch(POST_MISSIONS, {
        academic_name: this.academic_name,
        activities_name: this.activities_name,
        source_type: this.type,
        mission_point: this.point,
        start_at: this.start,
        end_at: this.end,
        mission_sort: this.numberN,
        source_id: this.sourceId,
        mission_status: 1,
      }).catch(() => {});
      // const { rows, count } = this.$store.getters.missions;
      // this.total_rows = count || 0;
      // this.list = rows;
      // console.log('res', this.goSave);
      this.isLoading = false;
      this.$router.push('/missions');
    },

    async getAcademyList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_ACADEMYS, {
        page: 1,
        per_page: 17,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.academys;
      this.total_rows = count || 0;
      this.sourceList = rows;
      this.isLoading = false;
    },
    async getActivitysList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_ACTIVITYS, {
        page: 1,
        per_page: 17,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.activitys;
      this.total_rows = count || 0;
      this.sourceList = rows;
      console.log('act', rows);
      this.isLoading = false;
    },
  },
};
</script>
